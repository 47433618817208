<template>
  <section>
    <!--工具条-->
    <toolbar
      :buttonList="buttonList"
      @callFunction="callFunction"
      :buttonListmsg="buttonListmsg"
    ></toolbar>
    <!--列表内容-->
    <el-table
      :data="users"
      row-key="Id"
      lazy
      :load="load"
      :tree-props="{ children: 'children', hasChildren: 'IsHasChildren' }"
      :fit="true"
      highlight-current-row
      @row-dblclick="checkInfo"
      @current-change="selectCurrentRow"
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '0px' }"
      :header-cell-style="{ background: '#dce4f1', color: '#000000' }"
      :row-class-name="tableRowClassName"
      style="width: 100%"
    >
      <el-table-column type="index"></el-table-column>
      <el-table-column
        label="工作项目"
        prop="WorkItem"
        min-width="260px"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <i
            class="el-icon-s-flag"
            v-if="scope.row.IsHasChildren == true"
          ></i>
          <i
            class="el-icon-s-flag"
            v-if="
              localuser != scope.row.PersonOfDuty &&
              scope.row.IsHasChildren == false
            "
          ></i>
          {{ scope.row.WorkItem }}
        </template>
      </el-table-column>
      <el-table-column
        label="工作类型"
        prop="WorkType"
        :formatter="formatCommonTaskClassifyText"
        min-width="100px"
      ></el-table-column>
      <el-table-column label="计划完成时间" prop="PlanComplateTime" min-width="110px" :formatter="formatPlanComplateTime">
          <template slot-scope = "scope">
              <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
                  <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
              </div>
              <div v-else>
                  <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
              </div>
          </template>
      </el-table-column>
      <el-table-column label="权重" min-width="75" prop="Rate">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.Rate == null"> 0 </el-tag>
          <el-tag v-else>{{ scope.row.Rate }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="所属年份"
        prop="OfYear"
        min-width="100px"
        :formatter="formatOfYear"
      ></el-table-column>
      <el-table-column
        label="责任人"
        min-width="70px"
        prop="PersonOfDuty"
      ></el-table-column>
      <el-table-column
        label="任务状态"
        prop="Status"
        align="center"
        min-width="100px"
      >
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div
              slot="content"
              v-if="scope.row.Status == 0 || scope.row.Status == 2"
            >
              已完成{{ scope.row.TaskProgressValue }}%
            </div>
            <div
              slot="content"
              v-if="
                scope.row.Status == 1 ||
                scope.row.Status == 4 ||
                scope.row.Status == 5
              "
            >
              审核中
            </div>
            <div
              slot="content"
              v-if="scope.row.Status == 6 || scope.row.Status == 3"
            >
              已退回
            </div>
            <div slot="content" v-if="scope.row.Status == -1">已取消</div>
            <div>
              <el-tag v-if="scope.row.Status == 0" effect="dark">
                进行中</el-tag
              >
              <el-tag v-if="scope.row.Status == 1" effect="dark" type="warning"
                >已完成审核中</el-tag
              >
              <el-tag v-if="scope.row.Status == 2" effect="dark" type="success"
                >已完成</el-tag
              >
              <el-tag v-if="scope.row.Status == -1" effect="dark" type="info"
                >已取消</el-tag
              >
              <el-tag v-if="scope.row.Status == 3" effect="dark" type="danger"
                >退回</el-tag
              >
              <el-tag v-if="scope.row.Status == 4" effect="dark" type="warning"
                >新建任务待审核</el-tag
              >
              <el-tag v-if="scope.row.Status == 5" effect="dark" type="danger"
                >取消任务待审核</el-tag
              >
              <el-tag v-if="scope.row.Status == 6" effect="dark" type="danger"
                >新建任务退回</el-tag
              >
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="进度" prop="TaskProgressValue" :formatter="formatProgress" align="left" min-width="55px"></el-table-column>
      <el-table-column
        label="工作来源"
        prop="Source"
        min-width="80"
        align="center"
        >新建</el-table-column
      >
      <el-table-column
        label="创建时间"
        prop="CreateTime"
        min-width="80"
        :formatter="formatCreateTime"
      >
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content">
              {{ formatTipCreateTime(scope.row) }}
            </div>
            <div>
              {{ formatCreateTime(scope.row) }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="操作" min-width="160px">
        <template slot-scope="scope">
          <el-row>
            <el-tooltip v-if="localuser == scope.row.PersonOfDuty" placement="top" content="双击可以维护任务进度喔~" disabled="false">
                <el-button type="text" size="medium" @click="checkInfo(scope.row)">维护</el-button>
                <el-button type="text" v-if="scope.row.Status == 3 || scope.row.Status == 6" style="color: red" @click="readdSubmit(scope.row)">重新提交</el-button>
            </el-tooltip>
            <el-tooltip v-else placement="left" content="双击也可以查看任务进度哦~" disabled="false">
                <el-button type="text" size="medium" @click="checkInfo(scope.row)" >查看</el-button>
                <el-button type="text" v-if="scope.row.Status == 3 || scope.row.Status == 6" style="color: red" @click="readdSubmit(scope.row)">重新提交</el-button>
            </el-tooltip>
            <el-button type="text" size="medium"  @click="handleLookAboutTask(scope.row)">查看已关联任务</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <!--工作任务添加-->
    <el-dialog
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      style="top: -10%"
      @close="clickclose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @opened="addopen"
      center
    >
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px">追加年度计划</span>
      </template>
      <el-form
        :model="addForm"
        label-width="85px"
        :rules="addFormRules"
        ref="addForm"
        style="white-space: nowrap"
      >
        <el-form-item label="工作类型:">
          <el-col :span="3">
            <el-radio v-model="Leave" label="1">日常</el-radio>
          </el-col>
          <el-col :span="3">
            <el-radio v-model="Leave" label="2">项目</el-radio>
          </el-col>
        </el-form-item>
        <el-form-item label="工作项目:" prop="WorkItem">
          <el-input
            maxlength="30"
            show-word-limit
            v-model="addForm.WorkItem"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作描述:" prop="WorkDescription">
          <el-input
            maxlength="500"
            show-word-limit
            v-model="addForm.WorkDescription"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="考核依据:" prop="ExamBasis">
          <el-input
            maxlength="100"
            show-word-limit
            v-model="addForm.ExamBasis"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="核算方式:" prop="ScoreCalcMode">
          <el-input
            maxlength="100"
            show-word-limit
            v-model="addForm.ScoreCalcMode"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="计划完成时间:" prop="PlanComplateTime">
              <el-date-picker
                :picker-options="pickerOptions0"
                v-model="addForm.PlanComplateTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期"
                style="left: 7%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属年份:" prop="OfYear">
              <el-date-picker
                v-model="addForm.OfYear"
                type="year"
                value-format="yyyy-MM-dd"
                placeholder="请选择年份"
                :picker-options="pickerOptions0"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="权重:" prop="Rate">
              <el-input
                v-model="addForm.Rate"
                type="number"
                min="1"
                max="100"
                placeholder="请填写1~100范围内的数字"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="17">
                <el-form-item label="责任人:" prop="PersonOfDuty">
                  <el-input v-model="addForm.PersonOfDuty" readonly>{{
                    dutyneedname
                  }}</el-input>
                </el-form-item>
              </el-col>
              <el-col :span="2" >
              <el-button type="text">
                  <userchoosetool @dutycallFunction="dutycallFunction" :callbackmsg = "dutypeo"></userchoosetool>
              </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-form-item label="备注:" prop="Remark">
          <el-input
            maxlength="50"
            show-word-limit
            v-model="addForm.Remark"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="addSubmit" v-show="showSubmit"
          >提交</el-button
        >
        <el-button type="goon" @click="nextStep" v-show="showNext"
          >下一步</el-button
        >
      </div>
    </el-dialog>
    <!--工作任务添加并指派-->
    <el-dialog
      :visible.sync="addAssignFormVisible"
      v-model="addAssignFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      style="width: 50%; left: 20%"
      center
    >
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px">指派</span>
      </template>
      <el-form label-width="85px" style="white-space: nowrap">
        <el-row>
          <el-col :span="0.1" style="margin-top: 10px">
            <span style="color: red">*</span>
          </el-col>
          <el-col :span="20">
            <el-form-item label="被指派人:" prop="AssignName">
              <el-input
                v-model="addForm.AssignName"
                readonly
                placeholder="请选择被指派人"
                >{{ needName }}</el-input
              >
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="text">
              <userchoosetool
                @assigncallFunction="assigncallFunction"
                :callbackmsg="assignpeo"
              ></userchoosetool>
            </el-button>
          </el-col>
        </el-row>
        <!-- <el-row>
                                <el-col :span="0.1" style="margin-top:10px">
                                    <span style="color: red;">*</span>
                                </el-col>
                                <el-col :span="20">
                                        <el-form-item label="完成后需我审核:" prop="IsNeedApproval">
                                        <el-switch v-model="addForm.IsNeedApproval" style="left: 5%;"></el-switch>
                                    </el-form-item>
                                </el-col>
                            </el-row> -->
        <!-- <el-row>
                                <el-col :span="0.1" style="margin-top:10px">
                                    <span style="color: red;">*</span>
                                </el-col>
                                <el-col :span="23">
                                    <el-form-item label="工作项目:" prop="WorkItem2">
                                        <el-input v-model="WorkItem2" maxlength="30" show-word-limit></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row> -->
        <!-- <el-row>
                                <el-col :span="0.1" style="margin-top:10px">
                                    <span style="color: red;">*</span>
                                </el-col>
                                <el-col :span="23">
                                    <el-form-item label="工作描述:" prop="WorkDescription2">
                                        <el-input v-model="WorkDescription2" type="textarea" maxlength="500" show-word-limit></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row> -->
        <!-- <el-row style="height: 40px;">
                                <el-col :span="0.1" style="margin-top:3px">
                                    <span style="color: red;">*</span>
                                </el-col>
                                <el-col :span="2.5">
                                    <span>计划完成时间:</span>
                                </el-col>
                                <el-col :span="7">
                                    <el-date-picker :picker-options="pickerOptions0" v-model ="PlanComplateTime2" type = "date" value-format="yyyy-MM-dd" placeholder = "请选择日期"></el-date-picker>
                            </el-col>
                        </el-row> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="addSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--编辑任务界面-->
    <el-dialog
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center
    >
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px">编辑年度计划</span>
      </template>
      <el-form
        :model="editForm"
        label-width="85px"
        :rules="editFormRules"
        ref="editForm"
        style="white-space: nowrap"
      >
        <el-form-item label="工作项目:" prop="WorkItem">
          <el-input
            v-model="editForm.WorkItem"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="工作描述:" prop="WorkDescription">
          <el-input
            v-model="editForm.WorkDescription"
            type="textarea"
            maxlength="500"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="考核依据:" prop="ExamBasis">
          <el-input
            maxlength="100"
            show-word-limit
            v-model="editForm.ExamBasis"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="核算方式:" prop="ScoreCalcMode">
          <el-input
            maxlength="100"
            show-word-limit
            v-model="editForm.ScoreCalcMode"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="权重:" prop="Rate">
          <el-input
            v-model="editForm.Rate"
            type="number"
            min="1"
            max="100"
            placeholder="请填写1~100范围内的数字"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="计划完成时间:" prop="PlanComplateTime">
              <el-date-picker
                :picker-options="pickerOptions0"
                v-model="editForm.PlanComplateTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期"
                style="left: 7%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属年份:" prop="OfYear">
              <el-date-picker
                v-model="editForm.OfYear"
                type="year"
                value-format="yyyy-MM-dd"
                placeholder="请选择年份"
                :picker-options="pickerOptions0"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row style="height: 40px; margin-bottom: 20px;">
                        <el-col :span="0.1">
                            <span style="color: red;">*</span>
                        </el-col>
                        <el-col :span="2.5">
                            <span>计划完成时间:</span>
                        </el-col>
                        <el-col :span="7">
                            <el-date-picker :picker-options="pickerOptions0" v-model ="editForm.PlanComplateTime2" type = "date" value-format="yyyy-MM-dd" placeholder = "请选择日期"></el-date-picker>
                    </el-col>
                    </el-row> -->
        <el-form-item label="备注:" prop="Remark">
          <el-input
            v-model="editForm.Remark"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="editSubmit">提交</el-button>
      </div>
    </el-dialog>
    <!--查看界面-->
    <el-dialog
      :visible.sync="checkVisible"
      v-model="checkVisible"
      @close="checkclose"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      style="width: 145%; left: -400px; top: -40px"
      center
    >
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px">查看年度计划</span>
      </template>
      <div class="yearplandiv">
        <el-col :span="12">
          <el-form :model="checkForm" label-width="85px" ref="checkForm">
            <el-form-item label="工作类型:" prop="WorkItemType">
              <el-input v-model="WorkItemType" disabled></el-input>
            </el-form-item>
            <el-form-item label="工作项目:" prop="WorkItem">
              <el-input v-model="checkForm.WorkItem" disabled></el-input>
            </el-form-item>
            <el-form-item label="工作描述:" prop="WorkDescription">
              <el-input
                v-model="checkForm.WorkDescription"
                disabled
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 4 }"
              ></el-input>
            </el-form-item>
            <el-row>
              <!-- <el-col :span="12">
                            <el-form-item label="创建人:" prop="CreatedBy">
                                <el-input v-model="checkForm.CreatedBy" disabled></el-input>
                            </el-form-item>
                        </el-col> -->
              <el-col :span="12">
                <el-form-item label="责任人:" prop="PersonOfDuty">
                  <el-input
                    v-model="checkForm.PersonOfDuty"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="完成时间:" prop="PlanComplateTime">
              <el-date-picker
                v-model="checkForm.PlanComplateTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期"
                disabled
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="考核依据:" prop="ExamBasis">
              <el-input
                v-model="checkForm.ExamBasis"
                disabled
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 4 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="核算方式:" prop="ScoreCalcMode">
              <el-input
                v-model="checkForm.ScoreCalcMode"
                disabled
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 4 }"
              ></el-input>
            </el-form-item>
            <el-form-item label="权重:" prop="Rate">
              <el-input
                v-model="checkForm.Rate"
                type="number"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="备注:" prop="Remark">
              <el-input v-model="checkForm.Remark" disabled></el-input>
            </el-form-item>
            <!-- <el-form-item label="是否审核:" prop="IsNeedApproval">
                        <el-switch v-model="checkForm.IsNeedApproval" disabled></el-switch>
                    </el-form-item> -->
            <el-form-item label="任务进度:">
              <el-progress
                :percentage="pValue1"
                :stroke-width="12"
                style="margin-top: 10px"
              ></el-progress>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-card class="yearplancard">
            <div slot="header">
              <span style="font-size: 20px">工作进度维护</span>
              <el-row v-show="isyearplanpro">
                <el-button type="text" @click="addpro">新增</el-button>
                <!-- <el-button  type="text" @click="editpro">编辑</el-button> -->
                <el-button type="text" @click="deletepro">删除</el-button>
              </el-row>
            </div>
            <div class="yeardiv">
              <!--列表-->
              <el-table
                :data="yearplans"
                highlight-current-row
                :row-class-name="taskProgressRowClassName"
                @row-click="onRowClick"
                @current-change="selectProRow"
              >
                <el-table-column type="index"></el-table-column>
                <el-table-column label="进度内容" prop="ProgressDescription">
                  <template slot-scope="scope">
                    <span style="font-size: 3px">{{
                      scope.row.ProgressDescription
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="进度"
                  prop="ProgressValue"
                  min-width="50%"
                ></el-table-column>
                <el-table-column
                  label="添加时间"
                  min-width="85%"
                  prop="CreatedOn"
                  :formatter="formatCreatedOn"
                ></el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
      </div>
    </el-dialog>
    <!--新增工作进度界面-->
    <el-dialog
      :visible.sync="addProgressVisible"
      v-model="addProgressVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @opened="addyearplanopen"
      style="width: 80%; left: 15%"
      center
    >
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px">新增工作进度</span>
      </template>
      <el-form :model="addProgressForm" :rules="addProgressFormRule">
        <el-form-item label="进度内容:" prop="ProgressDescription">
          <el-input
            v-model="addProgressForm.ProgressDescription"
            maxlength="700"
            show-word-limit
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="具体进度:">

                        <el-row>
                            <el-col :span="11">
                                <el-slider v-model="pValue2" @input="handleProcessInput" :step="5"></el-slider>
                            </el-col>
                            <el-col :span="2.2"><div style="margin-left:9px">{{pValue2}}%</div></el-col>
                            <el-col :span="7">
                                <div style="margin-left:3px;font-size:12px">
                                    <i class="el-icon-info"></i>拖动滑块设置进度百分比
                                </div>
                            </el-col>
                        </el-row>

          <!-- <el-row>
            <el-col :span="15">
              <el-progress
                style="margin-top: 10px"
                :percentage="pValue2"
                :color="customColor"
              ></el-progress>
            </el-col>
            <el-col :span="1.5">
              <div>
                <el-button-group>
                  <el-button
                    icon="el-icon-plus"
                    @click="increase"
                    v-show="showplus"
                  ></el-button>
                  <el-button
                    icon="el-icon-minus"
                    style="float: right"
                    v-show="showminus"
                    @click="decrease"
                  ></el-button>
                </el-button-group>
              </div>
            </el-col>
          </el-row> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="checkSubmit">完成</el-button>
      </div>
    </el-dialog>
    <!--编辑工作进度界面-->
    <el-dialog
      :visible.sync="editProgressVisible"
      v-model="editProgressVisible"
      style="width: 80%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      center
    >
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px">编辑工作进度</span>
      </template>
      <el-form :model="editProgressForm">
        <el-form-item label="进度内容:" prop="ProgressDescription">
          <el-input
            v-model="editProgressForm.ProgressDescription"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="具体进度:">
          <el-progress :percentage="pValue2" :color="customColor"></el-progress>
          <div>
            <el-button
              icon="el-icon-plus"
              @click="increase"
              style="float: right"
            ></el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="editcheckSubmit">完成</el-button>
      </div>
    </el-dialog>
    <!--分解年度计划-->
    <el-dialog
      :visible.sync="resloveVisible"
      v-model="resloveVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      style="top: -10%"
      center
    >
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px">分解年度计划</span>
      </template>
      <el-form
        :model="resloveForm"
        label-width="85px"
        ref="resloveForm"
        style="white-space: nowrap"
        :rules="resloveFormRules"
      >
        <el-row>
          <el-col :span="12">
            <el-col :span="0.1" style="margin-top: 10px">
              <span style="color: red">*</span>
            </el-col>
            <el-col :span="17">
              <el-form-item label="需分解人员:" prop="DecomposesUserName">
                <el-input
                  v-model="resloveForm.DecomposesUserName"
                  readonly
                  placeholder="请选择需分解人"
                  >{{ needresloveName }}</el-input
                >
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-button type="text">
                <userchoosetool
                  @reslovecallFunction="reslovecallFunction"
                  :callbackmsg="reslovepeo"
                ></userchoosetool>
              </el-button>
            </el-col>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作类型:">
              <el-col :span="2.5">
                <el-radio v-model="resloveLeave" label="1">日常</el-radio>
              </el-col>
              <el-col :span="3">
                <el-radio v-model="resloveLeave" label="2">项目</el-radio>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="工作项目:" prop="WorkItem">
          <el-input
            maxlength="30"
            show-word-limit
            v-model="resloveForm.WorkItem"
            placeholder="请填写工作项目名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="工作描述:" prop="WorkDescription">
          <el-input
            maxlength="500"
            show-word-limit
            v-model="resloveForm.WorkDescription"
            type="textarea"
            placeholder="请填写工作描述"
          ></el-input>
        </el-form-item>
        <el-form-item label="考核依据:" prop="ExamBasis">
          <el-input
            maxlength="100"
            show-word-limit
            v-model="resloveForm.ExamBasis"
            type="textarea"
            placeholder="请填写考核依据"
          ></el-input>
        </el-form-item>
        <el-form-item label="核算方式:" prop="ScoreCalcMode">
          <el-input
            maxlength="100"
            show-word-limit
            v-model="resloveForm.ScoreCalcMode"
            type="textarea"
            placeholder="请填写核算方式"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="0.1">
            <span style="color: red">*</span>
          </el-col>
          <el-col :span="22">
            <el-form-item label="配置任务指标属性:">
              <el-button
                icon="el-icon-plus"
                type="primary"
                @click="addtable"
                size="mini"
                style="float: right"
              ></el-button>
              <el-table :data="resloveForm.YearPlanConfigs" max-height="250">
                <el-table-column type="index"></el-table-column>
                <el-table-column
                  label="属性名称"
                  prop="FieldName"
                ></el-table-column>
                <el-table-column
                  label="属性类型"
                  prop="FieldType"
                  :formatter="FieldTypeFormat"
                ></el-table-column>
                <el-table-column
                  label="属性单位"
                  prop="FieldUnit"
                ></el-table-column>
                <el-table-column
                  label="可填写长度"
                  prop="FieldLength"
                ></el-table-column>
                <el-table-column
                  label="是否必填"
                  prop="FieldMustInput"
                  :formatter="FieldMustInputFormat"
                ></el-table-column>
                <!-- <el-table-column label="值" prop="FieldMustInput">
                                        <template scope="scope">
                                            
                                            <el-input v-if="1==1" v-model="text1" ></el-input>
                                            <el-date-picker v-if="2==2"></el-date-picker>

                                        </template>


                                    </el-table-column> -->
                <el-table-column label="操作">
                  <template scope="scope">
                    <el-button
                      size="small"
                      type="danger"
                      @click="deletetable(scope.row)"
                      icon="el-icon-delete"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="resloveSubmit">分解</el-button>
      </div>
    </el-dialog>
    <!--新增属性-->
    <el-dialog
      :visible.sync="addresloveVisible"
      v-model="addresloveVisible"
      style="width: 40%; left: 30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      center
    >
      <template slot="title">
        <span style="color: #ffffff; font-size: 16px">新增属性</span>
      </template>
      <el-form
        :model="addresloveForm"
        label-width="85px"
        ref="addresloveForm"
        style="white-space: nowrap"
        :rules="addresloveFormRules"
      >
        <el-form-item label="属性名称:" prop="FieldName">
          <el-input
            v-model="addresloveForm.FieldName"
            placeholder="请填写属性名称"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="属性类型:" prop="FieldType">
          <el-select
            v-model="addresloveForm.FieldType"
            placeholder="请选择属性类型"
          >
            <el-option
              v-for="item in fieldTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="属性单位:" prop="FieldUnit">
          <el-input
            v-model="addresloveForm.FieldUnit"
            placeholder="请填写属性单位"
            maxlength="5"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="可填写长度:" prop="FieldLength">
          <el-input
            v-model="addresloveForm.FieldLength"
            placeholder="请输入可填写长度"
            type="number"
            oninput="if(value.length>4)value=value.slice(0,4)"
            min="0"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否必填:">
          <el-col :span="7">
            <el-radio v-model="NeedInput" label="1">是</el-radio>
          </el-col>
          <el-col :span="4">
            <el-radio v-model="NeedInput" label="2">否</el-radio>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="addresloveFormSubmit"
          >新增</el-button
        >
      </div>
    </el-dialog>



        <el-dialog
        :visible.sync="lookAboutTaskVisible" 
        v-model="lookAboutTaskVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        width="1000px"
        center>
        <template slot="title">
            <span style="color:#FFFFFF;font-size: 16px;">查看关联的任务列表</span>
        </template>

            <el-form>
                <el-form-item label="任务类型:">
                    <el-select v-model="taskClassify" style="width: 20%;" >
                        <el-option v-for="item in ClassifyModules" :key="item.value" :label="item.label" :value="item.value"
                        ></el-option>
                    </el-select>&nbsp;
                    <el-button type="goon" icon="el-icon-search" @click="handleSearchAboutTask">筛选</el-button>
                </el-form-item>
            </el-form>
            <el-form  label-width="85px" style="white-space:nowrap;">
                    <el-table
                        :data="aboutTaskList"
                        height="300px"
                        row-key = "Id"
                        lazy
                        :indent="30"
                        :load = "load"
                        :tree-props = "{children: 'children',hasChildren: 'IsHasChildren'}"
                        :row-class-name="MultitableRowClassName"
                        @row-dblclick="checkAboutInfo"
                        :row-style="{height:'40px'}"
                        :cell-style="{padding:'0px'}"
                        :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                        :fit="true">
                        <el-table-column type="index" min-width="50px"></el-table-column>
                        <el-table-column label="工作项目" prop="WorkItem" min-width="100px" show-overflow-tooltip></el-table-column>
                        <el-table-column label="计划完成时间" prop="PlanComplateTime" min-width="130px" :formatter="formatPlanComplateTime" ></el-table-column>
                        <el-table-column label="责任人" prop="PersonOfDuty" min-width="100px"></el-table-column>
                        <el-table-column label="进度" prop="TaskProgressValue" :formatter="formatProgress" align="left" min-width="50px"></el-table-column>
                        <el-table-column label="任务类型" prop="CommonTaskClassifyText" min-width="100px" :formatter="formatCommonTaskClassifyText"></el-table-column>
                        <el-table-column label="创建时间" prop="CreateTime" min-width="100px" :formatter = "formatCreateTime">
                            <template slot-scope="scope">
                                <el-tooltip placement="top">
                                    <div slot="content">
                                        {{ formatTipCreateTime( scope.row) }}
                                    </div>
                                    <div>
                                        {{ formatCreateTime( scope.row) }}
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="附件" prop="FileName" min-width="90px" show-overflow-tooltip></el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" size="medium" @click="checkAboutInfo(scope.row)">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="goon" @click="handleCloseAboutTaskList">关闭</el-button>
            </div>
        </el-dialog>

        <el-dialog
            :visible.sync="lookOneAboutTaskVisible" 
            v-model="lookOneAboutTaskVisible" 
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            style="width: 145%; left: -400px; top:-40px"
            center
            >
            <template slot="title">
                <span style="color:#FFFFFF;font-size: 16px;">查看任务</span>   
            </template>
            <div class="managercheckdiv">
                <el-col :span="12">
                    <el-form :model="checkForm" label-width="85px" ref="checkForm" style="white-space:nowrap;">
                    <el-form-item label="任务类型:" prop="CommonTaskClassifyText">
                        <el-input v-model="CommonTaskClassifyText" disabled></el-input>
                    </el-form-item>
                    <div v-show="showregularproject">
                            <el-row>
                                <el-col :span="17">
                                    <el-form-item label="例会来源:" prop="MeetingName">
                                        <el-input v-model="checkForm.MeetingName" disabled></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7">
                                    <el-form-item label="审核人:" prop="ApprovalName">
                                        <el-input v-model="checkForm.ApprovalName" disabled></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div v-show="showprofessionmeetong">
                            <el-row>
                                <el-col :span="17">
                                    <el-form-item label="会议名称:" prop="MeetingName">
                                        <el-input v-model="checkForm.MeetingName" disabled type="textarea" :autosize="{minRows:1,maxRows:2}"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7">
                                    <el-form-item label="指令人:" prop="InstructUserName">
                                        <el-input v-model="checkForm.InstructUserName" disabled></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    <el-form-item label="工作项目:" prop="WorkItem">
                        <el-input v-model="checkForm.WorkItem" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="工作描述:" prop="WorkDescription">
                        <el-input v-model="checkForm.WorkDescription" type="textarea" disabled :autosize="{minRows:1,maxRows:4}"></el-input>
                    </el-form-item>
                        <el-row>
                            <el-col :span="8" v-show="createPerson">
                            <el-form-item label="创建人:" prop="CreatedBy">
                                <el-input v-model="checkForm.CreatedBy" disabled></el-input>
                            </el-form-item>
                        </el-col>
                            <el-col :span="8" v-show="showAssign">
                            <el-form-item label="指派人:" prop="AssignName">
                                <el-input v-model="checkForm.AssignName" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="责任人:" prop="PersonOfDuty">
                                <el-input v-model="checkForm.PersonOfDuty" disabled></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row style="height: 40px; margin-bottom: 20px;">
                        <el-col :span="12">
                            <el-form-item label="计划完成时间:" prop="PlanComplateTime" label-width="35%">
                                <el-date-picker v-model ="checkForm.PlanComplateTime" type = "date" value-format="yyyy-MM-dd" disabled style="width: 90%"></el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="创建时间:" prop="CreateTime" label-width="40%">
                                <el-date-picker v-model ="checkForm.CreateTime" type = "date" value-format="yyyy-MM-dd" disabled style="width: 90%"></el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div v-show="showyearplan">
                        <el-form-item label="考核依据:" prop="ExamBasis">
                            <el-input v-model="checkForm.ExamBasis" type="textarea" disabled :autosize="{minRows:1,maxRows:4}"></el-input>
                        </el-form-item>
                        <el-form-item label="核算方式:" prop="ScoreCalcMode">
                            <el-input v-model="checkForm.ScoreCalcMode" type="textarea" disabled :autosize="{minRows:1,maxRows:4}"></el-input>
                        </el-form-item>
                        <el-form-item label="权重:" prop="Rate">
                            <el-input v-model="checkForm.Rate" type="number" disabled></el-input>
                        </el-form-item>
                    </div>
                    <div v-show="showorganize">
                            <el-row>
                            <el-col :span="12">
                                <el-form-item label="发起部门:" prop="OutDepartment">
                                    <el-input v-model="checkForm.OutDepartment" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="发起人员:" prop="OutPeople">
                                    <el-input v-model="checkForm.OutPeople" disabled></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="跨入部门:" prop="AcceptDepartment">
                                    <el-input v-model="checkForm.AcceptDepartment" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                            <el-form-item label="协同人员:" prop="PersonOfDuty">
                                <el-input v-model="checkForm.PersonOfDuty" disabled></el-input>
                            </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <el-form-item label="备注:" prop="Remark">
                        <el-input v-model="checkForm.Remark" disabled></el-input>
                    </el-form-item>
                    <el-row style="height: 40px;">
                        <el-col :span="2.5">
                            <span>是否需审核:</span>
                        </el-col>
                        <el-col :span="2">
                            <el-switch v-model="checkForm.IsNeedApproval" disabled></el-switch>
                        </el-col>
                    </el-row>
                    <el-form-item label="任务进度:">
                        <el-progress :percentage="pValue1" :stroke-width="12" style="margin-top: 10px;" ></el-progress>
                    </el-form-item>
                        <!-- <div v-show="showtoolbar" style="margin-left: 50%;">
                        <toolbar :buttonList="buttonList2" @callFunction="callFunction" :buttonListmsg="buttonListmsg2"></toolbar>
                        </div> -->
                    <el-form-item label="附件:">
                        <a target="_blank" :href="checkForm.FileAddress">{{checkForm.FileName}}</a>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="12">
                    <el-card id="managercheckcard">
                    <div slot="header">
                        <span style="font-size:20px">工作进度维护</span>
                        <!-- <el-row v-show="istaskpro">
                            <el-button  type="text">新增</el-button>
                            <el-button  type="text">删除</el-button>
                        </el-row>             -->
                    </div> 
                    <div class="tasksdiv">
                        <!--列表-->
                        <el-table
                            :data="tasks" 
                            highlight-current-row
                            >
                            <el-table-column type = "index"></el-table-column>
                            <el-table-column label="进度内容" prop="ProgressDescription">
                                <template slot-scope = "scope">
                                    <span style="font-size:3px;">{{scope.row.ProgressDescription}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="进度" prop="ProgressValue" min-width="50%"></el-table-column>
                            <el-table-column label="添加时间" min-width="85%" prop="CreatedOn" :formatter="formatCreatedOn"></el-table-column>
                            <el-table-column label="附件" min-width="45%" prop="FileAddress" >
                                <template slot-scope = "scope">
                                    <!-- <a target="_blank" :href="scope.row.FileAddress">{{scope.row.FileName}}</a> -->
                                    <ul>
                                        <li v-for="(item,idx) in scope.row.Files" :key="idx" style="line-height: 20px;" >
                                            <a target="_blank" :href="item.FileAddress">{{item.FileName}}</a>
                                        </li>
                                    </ul>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
                </el-col>
            </div>
            <!-- <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click.native="checkSubmit">完成</el-button>
            </div> -->
        </el-dialog>
        


  </section>
</template>
<script>
import util from "../../../util/date";
import { Loading } from "element-ui";
import {
  QueryYearPlanAboutTaskByYearplanId,
  QueryPageYearPlanByUserCode,
  GetMyUpLeader,
  GetClassifyList,
  GetUserPostList,
  AddYearPlan,
  QueryTaskProgressByTaskId,
  AddTaskProgress,
  UpdateTaskProgress,
  DeleteTaskProgress,
  DeleteTaskById,
  AssignTask,
  CancelTask,
  QueryChildTasksByParentId,
  UpdateTaskContent,
  QueryYearPlanChildTasksByParentId,
  ReSubmitApproval,
  UpdateYearPlanTaskById,
  ImportYearPlan,
  DecomposesYearPlan,
} from "../../api/oa";
import { getButtonList } from "../../promissionRouter";
import Userchoosetool from "../../components/Userchoosetool";
import Toolbar from "../../components/Toolbar";
export default {
  components: { Toolbar, Userchoosetool },
  data() {
    return {
      loading: "",

      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 10, // 每页的数据条数
      page: 1,
      localuser: null,
      proRow: null,
      progresscheck: null, //进行完成进度验证
      fileListUpload: [], //上传文件数组
      fileTemp: null,
      yearplanexcelList: [], //定义导入excel数据数组
      submitList: [], //最终导入数据
      cpoysubmitList: [], //最终导入数据复制
      yearplans: [],
      users: [],
      text1: "",
      buttonList: [],
      WorkItem2: null,
      WorkDescription2: null,
      PlanComplateTime2: null,
      upleader: null,
      pValue1: null, //进度条数值
      pValue2: null,
      customColor: "#6f7ad3", //进度条颜色
      WorkItemType: null,
      dutyneedname: null,
      description: null,
      needName: null,
      needresloveName: null,
      assigncontrol: true,
      showminus: false,
      showplus: true,
      itemcontent: null,
      singleAss: false, //是否单独指派
      dutypeo: "dutypeo",
      assignpeo: "assignpeo",
      reslovepeo: "reslovepeo",
      buttonListmsg: "taskmanage",
      Leave: "1",
      resloveLeave: "1",
      NeedInput: "1",
      isyearplanpro: true,
      showSubmit: true, //提交按钮是否显示
      showNext: false, //下一步按钮是否显示
      currentRow: null,
      currentProgressRowIndex: null,
      filters: {
        name: "",
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      addFormVisible: false,
      yearplantaskid:'',
      lookOneAboutTaskVisible:false,
      lookAboutTaskVisible:false,
      tasks:[],
      istaskpro: false,
      showAssign: false,
      createPerson: false,                    //创建人是否显示
      showyearplan: false,                    //年度计划查看界面显示控制
      showorganize: false,                     //跨部门协同查看界面显示控制
      showregularproject: false,               //例会来源查看界面控制
      showprofessionmeetong: false,            //专项会议查看界面控制
      CommonTaskClassifyText: null,
      editFormVisible: false,
      addAssignFormVisible: false, //新增并指派界面初始化
      checkVisible: false, //查看界面初始化
      addProgressVisible: false,
      editProgressVisible: false,
      excelVisible: false,
      resloveVisible: false, //分解年度计划
      addresloveVisible: false,
      CommonTaskClassifyList: [],
      addForm: {
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsAssign: false,
        IsNeedApproval: false,
        CreatedBy: null,
        CreatedId: null,
        AssignName: null,
        AssignCode: null,
        PersonOfDuty: null,
        PersonOfDutyCode: null,
      },
      addFormRules: {
        WorkItem: [
          { required: true, message: "请填写项目名称", trigger: "blur" },
        ],
        PlanComplateTime: [
          { required: true, message: "请选择计划完成时间", trigger: "blur" },
        ],
        WorkDescription: [
          { required: true, message: "请填写具体工作描述", trigger: "blur" },
        ],
        OfYear: [
          { required: true, message: "请选择所属年份", trigger: "blur" },
        ],
        Rate: [
          { required: true, message: "请填写权重(数字)", trigger: "blur" },
        ],
        ExamBasis: [
          { required: true, message: "请填写考核依据", trigger: "blur" },
        ],
        ScoreCalcMode: [
          { required: true, message: "请填写核算方式", trigger: "blur" },
        ],
      },
      addProgressFormRule: {
        ProgressDescription: [
          { required: true, message: "请输入进度描述", trigger: "blur" },
        ],
      },
      editFormRules: {
        WorkItem: [
          { required: true, message: "请填写工作项目", trigger: "blur" },
        ],
        WorkDescription: [
          { required: true, message: "请输入工作描述", trigger: "blur" },
        ],
        ExamBasis: [
          { required: true, message: "请填写考核依据", trigger: "blur" },
        ],
        ScoreCalcMode: [
          { required: true, message: "请填写核算方式", trigger: "blur" },
        ],
        Rate: [{ required: true, message: "请填写权重", trigger: "blur" }],
        PlanComplateTime: [
          { required: true, message: "请选择计划完成时间", trigger: "blur" },
        ],
        OfYear: [
          { required: true, message: "请选择所属年份", trigger: "blur" },
        ],
      },

      aboutTaskList:[],
      taskClassify: 0,
      ClassifyModules:[
          {
              value: 0,
              label: "全部"
          },
          {
              value: 1,
              label: "日常工作"
          },
          // {
          //     value: 2,
          //     label: "跨部门协调"
          // },
          {
              value: 3,
              label: "例会项目"
          },
          // {
          //     value: 4,
          //     label: "年度计划"
          // },
          {
              value: 5,
              label: "专项会议"
          },
      ],
      editForm: {
        //编辑表单初始化
        Id: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
      },
      checkForm: {
        //查看表单初始化
        Id: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null,
      },
      addProgressForm: {
        //进度新增表单初始化
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null,
      },
      editProgressForm: {
        //进度编辑表单初始化
        Id: null,
        ProgressDescription: null,
        CreatedBy: null,
        CreatedId: null,
      },
      excalForm: {
        TaskData: {
          CreatedId: null,
          CreatedBy: null,
        },
        YearPlanData: [],
      },
      resloveForm: {
        TaskId: null,
        YearPlanId: null,
        DecomposesUserName: null,
        DecomposesUserCode: null,
        WorkItem: null,
        WorkDescription: null,
        ExamBasis: null,
        ScoreCalcMode: null,
        YearPlanConfigs: [],
      },
      resloveFormRules: {
        WorkItem: [
          { required: true, message: "请填写工作项目", trigger: "blur" },
        ],
        WorkDescription: [
          { required: true, message: "请输入工作描述", trigger: "blur" },
        ],
        ExamBasis: [
          { required: true, message: "请填写考核依据", trigger: "blur" },
        ],
        ScoreCalcMode: [
          { required: true, message: "请填写核算方式", trigger: "blur" },
        ],
      },
      addresloveForm: {
        FieldName: null,
        FieldType: null,
        FieldLength: null,
      },
      addresloveFormRules: {
        FieldName: [
          { required: true, message: "请填写字段名称", trigger: "blur" },
        ],
        FieldType: [
          { required: true, message: "请选择字段类型", trigger: "blur" },
        ],
        FieldUnit: [
          { required: true, message: "请填写字段单位", trigger: "blur" },
        ],
        FieldLength: [
          { required: true, message: "请填写字段长度", trigger: "blur" },
        ],
      },
      fieldTypeOptions: [
        {
          value: 1,
          label: "文本",
        },
        {
          value: 2,
          label: "数字",
        },
        // },
        // {
        // value: 3,
        // label: "时间"
        // },
        // {
        // value: 4,
        // label: "日期"
        // },
      ],
    };
  },
  methods: {
    handleProcessInput(val)
    {
        if(this.pValue2<this.pValue1)
        {
            this.pValue2=this.pValue1;
        }
        if(this.progresscheck == true){
            if (this.pValue2 >= 100) {
                if(this.checkForm.IsNeedApproval == false){
                    this.$message({
                    message: '任务已完成，请提交！',
                    type: 'success'
                });
                }else{
                    this.$message({
                        message: '任务已完成，请提交审核！',
                        type: 'warning'
                    });
                }
                this.pValue2 = 100;
            }
        }else{
            if (this.pValue2 > 95) {
                    this.$message({
                    message: "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
                    type: "warning",
                    duration: 10000
                });
                this.pValue2 = 95;
            }
        }
    },
    handleCloseAboutTaskList()
    {
        this.lookAboutTaskVisible=false;
    },
    handleSearchAboutTask()
    {
        var taskclassify=this.taskClassify;
        // console.log(taskclassify);
        QueryYearPlanAboutTaskByYearplanId({yearplantaskid:this.yearplantaskid,taskclassify:taskclassify}).then(res=>{
            // console.log(res);
            this.aboutTaskList = res.data.response.data;
        });
    },
    MultitableRowClassName({row, rowIndex}){
        if (rowIndex % 2 !== 0) {
                return 'warning-row';
          }
          return '';     
    },
    handleLookAboutTask(row)
    {
        // console.log(row)
        this.yearplantaskid=row.Id;
        this.lookAboutTaskVisible=true;
        QueryYearPlanAboutTaskByYearplanId({yearplantaskid:this.yearplantaskid,taskclassify:0}).then(res=>{
            // console.log(res);
            this.aboutTaskList = res.data.response.data;
        });
    },
    callFunction(item) {
      this.filters = {
        name: item.search,
      };
      this[item.Func].apply(this, item);
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    taskProgressRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    onRowClick(row, event, column) {
      //行点击消除new标记
      this.currentProgressRowIndex = row.index;
    },
    formatPlanComplateTime: function (row, colume) {
      return !row.PlanComplateTime || row.PlanComplateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.PlanComplateTime), "yyyy-MM-dd");
    },
    formatOfYear: function (row, colume) {
      return !row.OfYear || row.OfYear == ""
        ? ""
        : util.formatDate.format(new Date(row.OfYear), "yyyy");
    },
    formatProgress:function(row,col)
    {
        return row.TaskProgressValue+"%";
    },
    formatStatus: function (row, column) {
      return row.Status == 0
        ? "进行中"
        : row.Status == 1
        ? "审批中"
        : row.Status == 2
        ? "已完成"
        : row.Status == -1
        ? "已取消"
        : row.Status == 3
        ? "退回"
        : "未知";
    },
    formatSource: function (row, column) {
      return row.ParentId != null
        ? "被指派"
        : row.ParentId == null
        ? "新建"
        : "未知";
    },
    formatCreateTime: function (row, column) {
      return !row.CreateTime || row.CreateTime == ""
        ? ""
        : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return !row.CreateTime || row.CreateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm");
    },
    formatCreatedOn: function (row, colume) {
      return !row.CreatedOn || row.CreatedOn == ""
        ? ""
        : util.formatDate.format(new Date(row.CreatedOn), "yyyy-MM-dd hh:mm");
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if(row.TaskClassify ==1){  
          return row.CommonTaskClassifyText;
      }else{
          if(row.TaskClassify ==2)
          {
              return "跨部门协调";
          }
          else if(row.TaskClassify ==3)
          {
              return "例会项目";
          }else if(row.TaskClassify ==4)
          {
              return "年度计划";
          }else if(row.TaskClassify ==5){
              return "专项会议";
          }
          else {
              return "其他";
          }
      }
    },
    FieldTypeFormat: function (row, colume) {
      if (row.FieldType == 1) {
        return "文本";
      } else if (row.FieldType == 2) {
        return "数字";
      } else if (row.FieldType == 3) {
        return "时间";
      } else {
        return "日期";
      }
    },
    FieldMustInputFormat: function (row, colume) {
      if (row.FieldMustInput == 1) {
        return "是";
      } else {
        return "否";
      }
    },
    selectchange(val) {
      var ls = this.CommonTaskClassifyList;
      for (var a = 0; a < ls.length; a++) {
        if (ls[a].Id == val) {
          if (ls[a].IsNeedUpLeaderApproval == 1) {
            this.$message({
              message:
                "该任务类型下的任务需上级领导" +
                this.upleader +
                "审核成功后方可执行！",
              type: "warning",
              duration: 10000,
            });
            this.assigncontrol = false;
          } else {
            this.assigncontrol = true;
          }
        }
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getYearPlans();
      this.page = 1;
    },
    selectProRow(val) {
      this.proRow = val;
    },
    load(tree, treeNode, resolve) {
      let para = {
        taskId: tree.Id,
      };
      QueryYearPlanChildTasksByParentId(para).then((res) => {
        resolve(res.data.response);
      });
    },
    //获取上级级领导
    getLeader() {
      // var user = JSON.parse(window.localStorage.user);
      // let para = { userCode: user.sub };
      // GetMyUpLeader(para).then((res) => {
      //   this.upleader = res.data.response;
      // });
    },
    getYearPlans() {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        userCode: user.sub,
        addmode: 0,
        pageIndex: this.page,
        pageSize: this.pageSize,
        queryKey: this.filters.name,
      };
      QueryPageYearPlanByUserCode(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
      });
    },
    //下一步
    nextStep() {
      if (
        this.addForm.WorkItem != null &&
        this.addForm.WorkDescription != null &&
        this.addForm.PlanComplateTime != null
      ) {
        this.addAssignFormVisible = true;
        this.addForm.WorkItem2 = this.addForm.WorkItem;
        this.addForm.WorkDescription2 = this.addForm.WorkDescription;
      } else {
        this.tipMessage();
      }
    },
    //新增
    handleAdd() {
      this.showNext = false;
      this.showSubmit = true;
      this.needName = null;
      this.addFormVisible = true;
      var user = JSON.parse(window.localStorage.user);
      this.dutyneedname = user.name;
      this.addForm.PersonOfDuty = user.name;
      this.addForm.PersonOfDutyCode = user.sub;
      // this.$message({
      //     message: '该年度计划需上级领导审核通过后方可执行!',
      //     type: 'warning'
      // });
      GetClassifyList().then((res) => {
        this.CommonTaskClassifyList = res.data.response;
      });
    },
    //新增提交
    addSubmit() {
      if (this.singleAss == false) {
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.addForm);
            para.WorkType = this.Leave;
            para.CommonTaskClassifyText = 0;
            var user = JSON.parse(window.localStorage.user);
            if (user && user.sub != null) {
              para.CreatedId = user.sub;
              para.CreatedBy = user.name;
            } else {
              this.$message({
                message: "用户信息为空，请先登录",
                type: "error",
              });
              _this.$router.replace(
                _this.$route.query.redirect ? _this.$route.query.redirect : "/"
              );
            }
            if (
              this.addForm.WorkItem != null &&
              this.addForm.WorkDescription != null &&
              this.addForm.PlanComplateTime != null
            ) {
              this.Loadingstart();
              AddYearPlan(para).then((res) => {
                if (res.data.success) {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.Loadignend();
                  this.$refs["addForm"].resetFields();
                  this.addFormVisible = false;
                  this.addAssignFormVisible = false;
                  this.needName = null;
                  this.dutyneedname = null;
                  this.getYearPlans();
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            } else {
              this.tipMessage();
            }
            // }
          }
        });
      } else {
        if (
          this.addForm.AssignName == null ||
          this.WorkItem2 == null ||
          this.WorkDescription2 == null ||
          this.PlanComplateTime2 == null
        ) {
          this.tipMessage();
          return;
        }
        this.Loadingstart();
        let para = Object.assign({}, this.addForm);
        para.WorkItem2 = this.WorkItem2;
        para.WorkDescription2 = this.WorkDescription2;
        para.PlanComplateTime2 = this.PlanComplateTime2;
        para.Id = this.currentRow.Id;
        para.CommonTaskClassify = 4;
        AssignTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.Loadignend();
            this.addAssignFormVisible = false;
            this.needName = null;
            this.singleAss = false;
            this.getYearPlans();
            //  window.location.reload();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }
    },
    //退回后再次提交
    readdSubmit(row) {
      this.$confirm(
        "请尽量完善任务描述后再次提交,确定要提交吗？",
        "提示",
        {}
      ).then(() => {
        let para = {
          taskId: row.Id,
          taskClassify: row.TaskClassify,
          taskParentId: row.ParentId,
        };
        ReSubmitApproval(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.getYearPlans();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      });
    },
    //指派
    handleAssign() {
      let row = this.currentRow;
      var user = JSON.parse(window.localStorage.user);
      if (!row) {
        this.$message({
          message: "请选择要指派的一行数据！",
          type: "error",
          duration: 10000,
        });
        return;
      } else if (row.Status != 0) {
        this.$message({
          message: "当前年度计划未审核通过，不能指派",
          type: "error",
          duration: 10000,
        });
      } else {
        if (row.PersonOfDuty != user.name) {
          this.$message({
            message: "非当前年度计划责任人，不能指派",
            type: "error",
            duration: 10000,
          });
        } else {
          this.WorkItem2 = row.WorkItem;
          this.WorkDescription2 = row.WorkDescription;
          this.addAssignFormVisible = true;
          this.singleAss = true;
        }
      }
    },
    //取消任务
    handleCancel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要取消的一行数据！",
          type: "error",
          duration: 10000,
        });
        return;
      }
      var ucode = JSON.parse(window.localStorage.user);
      this.$confirm("确认取消任务吗？", "提示", {}).then(() => {
        let para = {
          taskId: row.Id,
          wantCancelUserCode: ucode.sub,
          wantCancelUserName: ucode.name,
        };
        CancelTask(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.currentRow = null;
            this.getYearPlans();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      });
    },
    //编辑
    handleEdit() {
      let row = this.currentRow;
      var ucode = JSON.parse(window.localStorage.user);
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
          duration: 10000,
        });
        return;
      } else if (row.Status == 4) {
        this.$message({
          message: "该年度计划仍在审核中,不能编辑！",
          type: "error",
          duration: 10000,
        });
        return;
      } else if (ucode.name != row.PersonOfDuty) {
        this.$message({
          message: "非该年度计划责任人,不能编辑！",
          type: "error",
          duration: 10000,
        });
        return;
      } else if (row.TaskProgressValue != 0) {
        this.$message({
          message: "当前任务已开始进行，不能编辑",
          type: "error",
          duration: 10000,
        });
      } else {
        this.editFormVisible = true;
        this.editForm = Object.assign({}, row);
      }
    },
    //编辑提交
    editSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          let para = Object.assign({}, this.editForm);
          para.Id = this.editForm.YearPlanId;
          para.TaskId = this.editForm.Id;
          if (
            this.editForm.WorkItem == null ||
            this.editForm.WorkDescription == null ||
            this.editForm.ExamBasis == null ||
            this.editForm.ScoreCalcMode == null ||
            this.editForm.Rate == null ||
            this.editForm.PlanComplateTime == null ||
            this.editForm.OfYear == null
          ) {
            this.tipMessage();
            return;
          }
          this.Loadingstart();
          UpdateYearPlanTaskById(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.Loadignend();
              this.$refs["editForm"].resetFields();
              this.currentRow = null;
              this.editFormVisible = false;
              this.getYearPlans();
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        }
      });
    },
    //分解任务
    handleResolve() {
      let row = this.currentRow;
      var user = JSON.parse(window.localStorage.user);
      if (!row) {
        this.$message({
          message: "请选择要分解的一行数据！",
          type: "error",
          duration: 10000,
        });
        return;
      } else if (row.PersonOfDuty != user.name) {
        this.$message({
          message: "非当前年度计划责任人，不能分解",
          type: "error",
          duration: 10000,
        });
        return;
      }
      this.resloveVisible = true;
      this.resloveForm.WorkItem = row.WorkItem;
      this.resloveForm.WorkDescription = row.WorkDescription;
      this.resloveForm.ExamBasis = row.ExamBasis;
      this.resloveForm.ScoreCalcMode = row.ScoreCalcMode;
      this.resloveLeave = row.WorkType.toString();
    },
    addtable() {
      this.addresloveVisible = true;
      this.$refs.addresloveForm.resetFields();
    },
    addresloveFormSubmit() {
      if (
        this.addresloveForm.FieldName == null ||
        this.addresloveForm.FieldType == null ||
        this.addresloveForm.FieldUnit == null ||
        this.addresloveForm.FieldLength == null
      ) {
        this.$message({
          message: "请填写完整表单!",
          type: "warning",
          duration: 10000,
        });
        return;
      }
      var ucode = JSON.parse(window.localStorage.user);
      let para = Object.assign({}, this.addresloveForm);
      para.CreatedBy = ucode.name;
      para.CreatedId = ucode.sub;
      para.FieldMustInput = this.NeedInput;
      this.resloveForm.YearPlanConfigs.push(para);
      this.addresloveVisible = false;
    },
    deletetable(row) {
      this.$confirm("确认要删除吗？", "提示", {
        type: "warning",
      }).then((_) => {
        this.resloveForm.YearPlanConfigs.splice(
          this.resloveForm.YearPlanConfigs.indexOf(row),
          1
        );
      });
    },
    resloveSubmit() {
      if (
        this.resloveForm.DecomposesUserName == null ||
        this.resloveForm.WorkItem == null ||
        this.resloveForm.WorkDescription == null ||
        this.resloveForm.ExamBasis == null ||
        this.resloveForm.ScoreCalcMode == null
      ) {
        this.$message({
          message: "请填写完整表单!",
          type: "warning",
          duration: 10000,
        });
        return;
      } else if (this.resloveForm.YearPlanConfigs.length == 0) {
        this.$message({
          message: "多行数据为必填，请填写多行数据!",
          type: "warning",
          duration: 10000,
        });
      }
      let para = Object.assign({}, this.resloveForm);
      para.WorkType = this.resloveLeave;
      para.TaskId = this.currentRow.Id;
      para.YearPlanId = this.currentRow.YearPlanId;
      this.$confirm("确认分解此年度计划吗？", "提示", {}).then(() => {
        this.Loadingstart();
        DecomposesYearPlan(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.Loadignend();
            this.$refs["resloveForm"].resetFields();
            this.currentRow = null;
            this.resloveVisible = false;
            this.getYearPlans();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      });
    },
    //删除
    handleDelete() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
          duration: 10000,
        });
        return;
      } else if (row.ParentId != null) {
        this.$message({
          message: "当前任务为子任务，不能删除",
          type: "error",
          duration: 10000,
        });
      } else {
        this.$confirm("确认删除年度计划任务吗？", "提示", {}).then(() => {
          let para = { taskId: row.Id };
          DeleteTaskById(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.currentRow = null;
              this.getYearPlans();
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }
          });
        });
      }
    },
    //双击查看详细信息
    checkInfo(row) {
      var ucode = JSON.parse(window.localStorage.user);
      this.checkVisible = true;
      if (row.WorkType == 1) {
        this.WorkItemType = "日常";
      } else {
        this.WorkItemType = "项目";
      }
      this.pValue1 = row.TaskProgressValue;
      this.pValue2 = row.TaskProgressValue;
      this.progresscheck = row.ChildrenProgressComplated;
      if (row.PersonOfDuty != ucode.name || row.Status != 0) {
        this.isyearplanpro = false;
      } else {
        if (this.pValue1 < 100) {
          if (this.pValue1 == 95 && this.progresscheck == false) {
            this.isyearplanpro = false;
          } else {
            this.isyearplanpro = true;
          }
        } else {
          this.isyearplanpro = false;
        }
      }
      GetClassifyList().then((res) => {
        this.CommonTaskClassifyList = res.data.response;
      });
      this.checkForm = Object.assign({}, row);
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        this.yearplans = res.data.response;
      });
    },
    checkAboutInfo(row){
        var ucode = JSON.parse(window.localStorage.user);
        this.lookOneAboutTaskVisible = true;
        if(row.WorkType == 1){
            this.WorkItemType = "日常"
        }else{
            this.WorkItemType= "项目"
        }
        this.CommonTaskClassifyText=row.CommonTaskClassifyText;
        // console.log('row');
        // console.log(row);
        // console.log(this.CommonTaskClassifyText);
        this.pValue1 = row.TaskProgressValue;
        this.pValue2 = row.TaskProgressValue;
        this.progresscheck = row.ChildrenProgressComplated;
        if(row.PersonOfDuty != ucode.name || row.Status != 0){
          this.isyearplanpro = false;
        }else{
            if(this.pValue1 < 100){
              if(this.pValue1 == 95 && this.progresscheck == false){
                  this.isyearplanpro = false;
              }else{
                  this.isyearplanpro = true;
              }  
          }else{
              this.isyearplanpro = false;
          }
        }
        GetClassifyList().then((res) => {
            this.CommonTaskClassifyList = res.data.response;
        });
        this.checkForm = Object.assign({},row);
        let para = {taskId: row.Id};
        QueryTaskProgressByTaskId(para).then((res) => {
            // this.tasks = res.data.response;
            var data =res.data.response;
            let formatData=new Array();
            for(var a=0;a<data.length;a++)
            {
                  var element = data[a];
                  element.Files=[];
                  if(element.FileName!=null)
                  {
                      var arr=element.FileName.split(",");
                      var arr2=element.FileAddress.split(",");
                      var files=[];
                      for(var d=0;d<arr.length;d++)
                      {
                          var f={};
                          f.FileName=arr[d];
                          f.FileAddress=arr2[d];
                          files.push(f);
                      }
                      element.Files=files;
                  }
                  formatData.push(element);
            }
            this.tasks = formatData;
        });
    },
    checkclose() {
      this.checkVisible = false;
      this.pValue1 = 0;
      this.getYearPlans();
    },
    //进度新增
    addpro() {
      this.addProgressVisible = true;
      this.addProgressForm.ProgressDescription = null;
      this.showminus = false;
      this.showplus = true;
    },
    addyearplanopen() {
      if (this.progresscheck == false) {
        this.$message({
          message:
            "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
          type: "warning",
          duration: 10000,
        });
      }
    },
    //进度新增提交
    checkSubmit() {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        taskID: this.checkForm.Id,
        progressDescription: this.addProgressForm.ProgressDescription,
        progressValue: this.pValue2,
        createdId: user.sub,
        createdBy: user.name
      };
      if (this.addProgressForm.ProgressDescription == null) {
        this.$message({
          message: "请填写进度内容",
          type: "warning",
        });
      } else {
        this.Loadingstart();
        AddTaskProgress(para).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            this.Loadignend();
            this.addProgressVisible = false;
            let para = { taskId: this.checkForm.Id };
            QueryTaskProgressByTaskId(para).then((res) => {
              this.yearplans = res.data.response;
              this.RefreshProgress(this.yearplans);
            });
            this.getYearPlans();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      }
    },
    //进度编辑
    editpro() {
      let row = this.proRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      this.editProgressVisible = true;
      this.editProgressForm = Object.assign({}, row);
    },
    //进度编辑提交
    editcheckSubmit() {
      let para = {
        id: this.editProgressForm.Id,
        taskID: this.checkForm.Id,
        progressDescription: this.editProgressForm.ProgressDescription,
        progressValue: this.pValue2,
        createdId: this.checkForm.CreatedId,
        createdBy: this.checkForm.CreatedBy,
      };
      UpdateTaskProgress(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.editProgressVisible = false;
          let para = { taskId: this.checkForm.Id };
          QueryTaskProgressByTaskId(para).then((res) => {
            this.yearplans = res.data.response;
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    //删除选中进度
    deletepro() {
      let row = this.proRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
          duration: 10000,
        });
        return;
      }
      var length = this.yearplans.length;
      if (this.currentProgressRowIndex < length - 1) {
        this.$message({
          message: "只允许删除最后一条进度",
          type: "error",
          duration: 10000,
        });
        return;
      }
      this.$confirm("确认删除进度吗？", "提示", {}).then(() => {
        DeleteTaskProgress({ id: row.Id }).then((res) => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success",
            });
            let para = { taskId: this.checkForm.Id };
            QueryTaskProgressByTaskId(para).then((res) => {
              this.yearplans = res.data.response;
              this.RefreshProgress(this.yearplans);
            });
            this.getYearPlans();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        });
      });
    },
    //责任人回调
    dutycallFunction(newdata) {
      this.dutyneedname = null;
      this.addForm.PersonOfDutyCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.PersonOfDuty = res.data.data[0].name;
          this.dutyneedname = this.addForm.PersonOfDuty;
        }
      });
    },
    //指派人回调
    assigncallFunction(newdata) {
      this.needName = null;
      this.addForm.AssignCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.AssignName = res.data.data[0].name;
          this.needName = this.addForm.AssignName;
        }
      });
    },
    //分解人回调
    reslovecallFunction(newdata) {
      this.needresloveName = null;
      this.resloveForm.DecomposesUserCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then((res) => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.resloveForm.DecomposesUserName =
            res.data.data[0].name;
          this.needresloveName = this.resloveForm.DecomposesUserName;
        }
      });
    },
    clickAssign() {
      if (this.addForm.IsAssign == true) {
        this.showNext = true;
        this.showSubmit = false;
      } else {
        this.showSubmit = true;
        this.showNext = false;
      }
    },
    clickclose() {
      this.addForm = {
        //新增表单初始化
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsAssign: false,
        CreatedBy: null,
        CreatedId: null,
        AssignName: null,
        AssignCode: null,
        PersonOfDuty: null,
        PersonOfDutyCode: null,
      };
      this.$refs.addForm.resetFields();
    },
    increase() {
      this.pValue2 += 5;
      if (this.pValue2 > this.pValue1) {
        this.showminus = true;
      }
      if (this.progresscheck == true) {
        if (this.pValue2 > 100) {
          if (this.checkForm.IsNeedApproval == false) {
            this.$message({
              message: "任务已完成，请提交！",
              type: "success",
            });
          } else {
            this.$message({
              message: "任务已完成，请提交审核！",
              type: "warning",
            });
          }

          this.pValue2 = 100;
        }
      } else {
        if (this.pValue2 == 95) {
          this.$message({
            message:
              "当前任务被指派人并未都完成被指派任务,故进度最大只能添加至95%！",
            type: "warning",
            duration: 10000,
          });
          this.showplus = false;
          this.this.pValue2 = 95;
        }
      }
    },
    decrease() {
      this.pValue2 -= 5;
      if (this.pValue2 <= this.pValue1) {
        this.showminus = false;
      }
      if (this.progresscheck == false) {
        if (this.pValue2 < 95) {
          this.showplus = true;
        } else {
          this.showplus = true;
        }
      }
    },
    tipMessage() {
      this.$message({
        message: "填写未完成，请继续填写",
        type: "warning",
      });
    },
    RefreshProgress(tasksProgressRow) {
      var rowlength = tasksProgressRow.length;
      if (rowlength > 0) {
        this.pValue2 =
          tasksProgressRow[tasksProgressRow.length - 1].ProgressValue;
      } else {
        this.pValue2 = 0;
      }
      this.pValue1 = this.pValue2;
      if (this.pValue1 == 95 && this.progresscheck == false) {
        this.istaskpro = false;
      } else {
        this.istaskpro = true;
      }
    },
    addopen() {
      // this.$message({
      //   message: "该年度计划需上级领导审核通过后方可执行!",
      //   type: "warning",
      // });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return "warning-row";
      }
      return "";
    },
    Loadingstart() {
      this.loading = Loading.service({
        lock: true,
        text: "加载中...",
        background: "rgba(0,0,0,0.1)",
      });
    },
    Loadignend() {
      this.loading.close();
    },
  },
  mounted() {
    this.getYearPlans();
    this.getLeader();
    var user = JSON.parse(window.localStorage.user);
    this.localuser = user.name;
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);
    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.yearplandiv,
.yearplancard {
  height: 600px;
}
.yeardiv {
  max-height: 400px;
  overflow: auto;
}

.managercheckdiv {
 height: 600px;
}
#managercheckcard{
    height: 600px;
}
</style>